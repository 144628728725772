function SoundOn() {
  return (
    <svg viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <path
          d="M69.463 35.697c-1.122-6.528-4.4-11.985-9.746-16.219a9.337 9.337 0 0 0-1.916-1.196 5.849 5.849 0 0 0-2.437-.535c-1.902 0-3.68.965-4.755 2.58-.448.573-1.38 1.007-2.3-.863C46.85 16.644 44.394 15 41.523 15c-1.658 0-3.301.559-4.885 1.653a26.67 26.67 0 0 0-2.954 2.373 501.336 501.336 0 0 0-6.976 6.62l-.19.014c-1.878.148-3.822.3-5.789.619-5.18.826-7.78 3.91-7.728 9.168.023 2.3.115 4.599.208 6.824l.023.614c.05 1.288.124 2.788.295 4.294.162 1.417.397 2.682.716 3.864.812 2.996 3.259 4.99 6.39 5.198.332.023.664.046.996.074.97.074 1.976.152 3.043.152.24 0 .48 0 .72-.013h.023c.037.027.106.083.203.18.933.946 1.902 1.855 2.84 2.737.392.37.789.744 1.186 1.122.065.06.13.12.194.176.319.267.628.535.932.803.836.73 1.7 1.486 2.71 2.184 1.418.974 2.849 1.722 4.257 2.234a8.815 8.815 0 0 0 3.006.55c3.065 0 5.65-1.704 7.09-4.677a15.29 15.29 0 0 0 .966-2.595c.028-.014.046-.05.06-.12.184-.95 1.025-1.5 1.39-.037a5.728 5.728 0 0 0 1.135 2.535 5.69 5.69 0 0 0 4.506 2.216c.845 0 1.671-.185 2.456-.545a8.486 8.486 0 0 0 1.662-1.043c1.851-1.431 3.153-3.163 4.234-4.672.027-.037.05-.074.074-.111 4.676-7.032 6.408-14.335 5.143-21.699l.004.005Z"
          fill="#fff"
        />
        <path
          d="M32.51 57.493c-1.356-1.293-2.677-2.503-3.937-3.777-.933-.946-1.948-1.463-3.333-1.413-1.44.051-2.89-.115-4.33-.207-1.377-.093-2.3-.831-2.655-2.138a22.192 22.192 0 0 1-.6-3.245c-.153-1.325-.218-2.66-.273-3.99-.097-2.437-.208-4.875-.23-7.312-.033-3.204 1.038-4.515 4.233-5.028 2.063-.332 4.164-.47 6.25-.642.656-.05 1.118-.295 1.584-.743a516.63 516.63 0 0 1 7.295-6.93c.78-.724 1.62-1.394 2.493-2.003 2.784-1.93 4.986-.605 5.983 2.16.397 1.095.572 2.3.665 3.468.448 5.507.79 11.024.683 16.555-.097 4.959-.43 9.898-1.302 14.783-.18.997-.498 2.004-.942 2.918-.997 2.059-2.788 2.816-4.949 2.036-1.168-.42-2.29-1.044-3.32-1.75-1.158-.794-2.178-1.782-3.319-2.742h.005ZM60.867 55.096c-1.02 1.436-2.046 2.761-3.389 3.8-.268.208-.55.415-.859.558-.73.337-1.482.143-1.948-.452-.466-.596-.443-1.371.055-2 .093-.12.204-.225.324-.322 3.68-3.098 5.701-7.175 6.823-11.74 1.034-4.188.817-8.33-1.025-12.276-1.403-2.997-3.24-5.693-6.038-7.6-.19-.129-.37-.272-.522-.438-.559-.61-.628-1.353-.208-1.99.448-.674 1.233-.919 2.008-.563.379.17.734.41 1.058.67 4.45 3.526 7.27 8.083 8.231 13.67 1.173 6.809-.692 12.963-4.506 18.693l-.004-.01Z"
          fill="#6177FB"
        />
        <path
          d="M52.178 43.582c.651-3.02.185-5.757-1.39-8.3-.553-.892-.452-1.797.278-2.337.688-.512 1.579-.425 2.239.337a6.711 6.711 0 0 1 1.09 1.722c1.347 3.167 1.81 6.427.858 9.802-.226.803-.42 1.62-.678 2.414-.199.614-.62 1.048-1.284 1.177-1.186.231-2.091-.78-1.814-2.068.189-.891.452-1.768.697-2.747h.004Z"
          fill="#6177FB"
        />
      </g>
      <defs>
        <filter
          id="a"
          x="9"
          y="15"
          width="64.86"
          height="65.439"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation=".5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_369_15"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend
            in2="effect1_dropShadow_369_15"
            result="effect2_dropShadow_369_15"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
          <feBlend
            in2="effect2_dropShadow_369_15"
            result="effect3_dropShadow_369_15"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
          <feBlend
            in2="effect3_dropShadow_369_15"
            result="effect4_dropShadow_369_15"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect4_dropShadow_369_15"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default SoundOn;

function SoundOff() {
  return (
    <svg fill="none" viewBox="0 0 82 82" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M68.335 43.8a6.83 6.83 0 0 0-1.337-1.725 119.629 119.629 0 0 0-1.725-1.573c.254-.268.509-.536.763-.81.602-.642 1.235-1.36 1.781-2.261 1.332-2.216.874-5.051-1.12-6.902-1.392-1.295-2.844-1.568-3.82-1.568-.879 0-1.758.227-2.549.657-.643.352-1.226.8-1.73 1.337-.532.569-1.04 1.133-1.577 1.735-.504-.477-1.008-.958-1.513-1.434-.615-.583-1.235-1.018-1.905-1.333a5.487 5.487 0 0 0-2.36-.531c-.406 0-.814.05-1.211.143a339.151 339.151 0 0 0-.315-4.214c-.148-1.804-.435-3.252-.902-4.552-1.318-3.65-4.08-5.832-7.392-5.832-1.66 0-3.307.56-4.893 1.656a26.844 26.844 0 0 0-2.96 2.377 534.531 534.531 0 0 0-6.99 6.633l-.19.014c-1.882.148-3.83.3-5.8.615-5.195.842-7.799 3.932-7.748 9.2.023 2.295.116 4.598.208 6.823l.028.63c.05 1.29.125 2.793.296 4.301a26.16 26.16 0 0 0 .717 3.872c.814 3.006 3.266 5 6.402 5.208.333.023.666.046.999.074.971.074 1.98.153 3.048.153.24 0 .481 0 .722-.014h.023c.037.028.106.083.203.18.935.949 1.906 1.86 2.845 2.743.393.37.791.745 1.189 1.124.065.06.13.12.194.176.32.268.63.537.934.805.838.73 1.703 1.49 2.716 2.188 1.415.976 2.854 1.725 4.264 2.239a8.834 8.834 0 0 0 3.012.55c3.071 0 5.661-1.707 7.105-4.686a15.778 15.778 0 0 0 1.3-4.01c.323-1.813.582-3.677.79-5.634a5.4 5.4 0 0 0 1.781.31c1.046 0 2.063-.31 2.938-.893.726-.485 1.24-1.022 1.61-1.415l.027-.028c.42-.444.856-.907 1.286-1.36.259.245.523.49.782.736.643.605 1.364 1.24 2.261 1.78.82.49 1.758.75 2.716.75 1.554 0 3.08-.68 4.186-1.869 1.707-1.832 2.054-4.274.911-6.37v.005Z"
        fill="#fff"
      />
      <path
        d="M32.39 57.52c-1.36-1.296-2.683-2.508-3.946-3.784-.934-.949-1.952-1.467-3.34-1.416-1.443.051-2.895-.115-4.339-.208-1.378-.093-2.303-.833-2.66-2.142a22.222 22.222 0 0 1-.6-3.251c-.153-1.328-.218-2.665-.273-3.997-.098-2.442-.209-4.885-.232-7.327-.032-3.21 1.041-4.524 4.242-5.037 2.067-.333 4.172-.472 6.263-.643.657-.051 1.12-.296 1.587-.745a518.069 518.069 0 0 1 7.308-6.943 23.148 23.148 0 0 1 2.498-2.007c2.789-1.934 4.995-.606 5.994 2.165.398 1.096.574 2.303.667 3.473.448 5.519.79 11.046.684 16.588-.097 4.968-.43 9.917-1.304 14.81-.18 1-.5 2.008-.944 2.924-.999 2.063-2.794 2.822-4.959 2.04-1.17-.42-2.294-1.045-3.325-1.753-1.161-.796-2.184-1.786-3.326-2.748h.005ZM59.552 36.103c.744-.823 1.406-1.573 2.086-2.299a2.75 2.75 0 0 1 .684-.532c.49-.268 1.022-.175 1.54.306.5.467.708 1.17.384 1.706-.346.574-.79 1.078-1.244 1.56-3.27 3.482-6.55 6.951-9.83 10.42-.286.301-.573.611-.915.838-.523.347-1.156.222-1.642-.268-.55-.556-.652-1.152-.389-1.712.144-.305.361-.574.588-.81 2.886-3.043 5.777-6.082 8.747-9.204l-.01-.005Z"
        fill="#6177FB"
      />
      <path
        d="M61.85 43.023c.823.745 1.573 1.406 2.299 2.086.208.195.393.43.532.685.268.49.176 1.022-.305 1.54-.468.5-1.17.708-1.707.384-.574-.347-1.078-.79-1.56-1.244-3.482-3.27-6.951-6.55-10.42-9.83-.301-.286-.611-.573-.838-.915-.347-.523-.222-1.157.269-1.642.555-.55 1.151-.653 1.711-.389.305.144.574.36.81.587 3.043 2.887 6.082 5.778 9.204 8.748l.005-.01Z"
        fill="#6177FB"
      />
    </svg>
  );
}

export default SoundOff;

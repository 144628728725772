'use client';

import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { PERCENTAGE_VISIBLE_TO_SEND_LOG } from '@/constants';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { sendLog } from '@/services/logs';

import styles from './styles.module.scss';

interface Props {
  event: string;
  value?: Record<string, unknown>;
  now?: boolean;
  threshold?: number;
  onEnterView?: () => void;
  full?: boolean;
}

export default function SendLogOnViewWrapper({
  children,
  event,
  value,
  now,
  threshold = PERCENTAGE_VISIBLE_TO_SEND_LOG,
  onEnterView,
  full,
}: PropsWithChildren<Props>) {
  const [isViewedLogSent, setIsViewedLogSent] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const entry = useIntersectionObserver(ref, {
    freezeOnceVisible: true,
    threshold,
  });
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible && !isViewedLogSent) {
      sendLog(event, value, { now });
      setIsViewedLogSent(true);
      onEnterView?.();
    }
  }, [isVisible, isViewedLogSent, event, value, now, onEnterView]);

  return (
    <div className={clsx(styles.container, full && styles.full)} ref={ref}>
      {children}
    </div>
  );
}

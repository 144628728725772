'use client';

import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import Button from '@/components/Button';
import SoundOff from '@/components/SVG/Creatives/SoundOff/picta';
import SoundOn from '@/components/SVG/Creatives/SoundOn/picta';
import Play from '@/components/SVG/Spotify/Play';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { sendLog } from '@/services/logs';

import styles from './styles.module.scss';

interface Props {
  loop?: boolean;
  hasSound?: boolean;
  isPausable?: boolean;
  className?: string;
  isActive?: boolean;
  id?: string;
  logsDetails?: {
    page: string;
    type: string;
  };
  onEnded?: () => void;
  onPlay?: () => void;
  handleSelect?: (id?: string) => void;
}

export default function Video({
  children,
  hasSound,
  isPausable,
  loop,
  className,
  isActive = true,
  id,
  logsDetails,
  onEnded,
  onPlay,
  handleSelect,
}: PropsWithChildren<Props>) {
  const [isMuted, setIsMuted] = useState(true);
  const [showPlayButton, setShowPlayButton] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const entry = useIntersectionObserver(videoRef, {
    threshold: 0.8,
  });

  const pauseVideo = () => {
    videoRef.current?.pause();
  };

  const playVideo = async () => {
    if (videoRef.current?.paused) {
      try {
        await videoRef.current.play();
      } catch (error) {
        if (error instanceof Error && error.name === 'NotAllowedError') {
          setShowPlayButton(true);
        }
      }
    }
  };

  const toggleVideo = () => {
    const isPaused = !!videoRef.current?.paused;
    if (isPaused) {
      handleSelect?.(id);
      playVideo();
      setShowPlayButton(false);
    } else {
      pauseVideo();
      setShowPlayButton(true);
    }
  };

  useEffect(() => {
    if (entry?.isIntersecting && isActive) {
      playVideo();
    } else if (videoRef.current?.played && isPausable) {
      pauseVideo();
      setShowPlayButton(true);
    }
  }, [entry, isActive, isPausable]);

  return (
    <div className={styles.player}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        playsInline
        muted={isMuted}
        className={clsx(styles.video, className)}
        loop={loop}
        ref={videoRef}
        onEnded={onEnded}
        onPlay={() => {
          onPlay?.();
          setShowPlayButton(false);
        }}
      >
        {children}
      </video>
      <div
        onClick={
          isPausable
            ? () => {
                toggleVideo();
                sendLog(`ui_video_play_button_tapped`, {
                  played: !videoRef.current?.paused,
                  ...logsDetails,
                });
              }
            : undefined
        }
        className={styles.controls}
      >
        {showPlayButton ? (
          <div className={styles.playContainer}>
            <Button theme="noStyle" pointer aria-label="play">
              <div className={styles.icon}>
                <Play />
              </div>
            </Button>
          </div>
        ) : null}
        {hasSound ? (
          <div className={styles.sound}>
            <Button
              aria-label={isMuted ? 'Unmute' : 'Mute'}
              theme="noStyle"
              pointer
              callback={(event) => {
                event.stopPropagation();
                if (videoRef.current) {
                  videoRef.current.muted = !videoRef.current.muted;
                  setIsMuted(videoRef.current.muted);
                }
                sendLog(`ui_sound_button_tapped`, {
                  sound: !videoRef.current?.muted,
                  ...logsDetails,
                });
              }}
            >
              <div className={styles.icon}>
                {isMuted ? <SoundOff /> : <SoundOn />}
              </div>
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
